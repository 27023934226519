/******************************************************************************
 * ****************************************************************************
 *  Child Component: Sidebar
 *  Dashboard is its parent component
 *  Functionality: draw the side bar for the application
 *
 *  States: no states
 *  Props:
 *  resetAllFilters - reset filters to their default state using the toggle
 *  toggleModal - reset filters to their default state using the toggle
 *  showModal - show/hide modal for about information
 *  resetCensus - reset census layers 
 * ***************************************************************************
******************************************************************************/

import React, { useState } from 'react';
import { Navbar} from 'reactstrap';

import FilterPanel from './Sidebar/filter-panel/FilterPanel';
import CensusPanel from './Sidebar/census-panel/CensusPanel';
import InfoModal from './Sidebar/Info-modal/InfoModal';

const Sidebar = (props) => {
    const {
        toggleModal,
        isModalOpen,
        resetAllFilters,
        resetCensus,

    } = props;

    const [isLayersOpen, setisLayersOpen] = useState(false);
    const [isCensusOpen, setisCensusOpen] = useState(false);

    // Reset map to default view
    const resetToDefaultView = () => {
        resetAllFilters();
        resetCensus();
        setisLayersOpen(false);
    };

    // Open filter panel
    const filterPanelToggle = (e) => {
        e.preventDefault();
        setisLayersOpen(!isLayersOpen);
        setisCensusOpen(false);
    };
    
    // Open census panel
    const censusPanelToggle = (e) => {
        e.preventDefault();
        setisCensusOpen(!isCensusOpen);
        setisLayersOpen(false);
    };
    
    // Show/hide modal
    const toggleModalEvt = (e) => {
        e.preventDefault();
        toggleModal();
    };

    let hrefLink = '/';
    return(
        <div id="main-sidebar-nav">

            {/* Navigation Links: Start */}
            <nav id="sidebar-nav">
                <Navbar id="sidebar-navigation" className="sidebar sidebar-navigation collapsed" role="navigation">
                    <ul className="sidebar-list">
                        <li className="sidebar-item reset-map">
                            <a href={hrefLink} onClick={() => resetToDefaultView()} title="Reset Map" aria-label="Reset map to default settings">
                                <span className="sidebar-item-lbl">Reset Map</span>
                                <span className="sr-only">Reset Map</span>
                            </a>
                        </li>

                        <li className= {isLayersOpen ? "active sidebar-item filters" : "sidebar-item filters"}>
                            <a href={hrefLink} title="Add Data Layers" onClick={filterPanelToggle} aria-label="Filter data and add layers to map">
                                <span className="sidebar-item-lbl">Add Data Layers</span>
                                <span className="sr-only">Add Data Layers</span>
                            </a>
                        </li>

                        <li className= {isCensusOpen ? "active sidebar-item data-layers" : "sidebar-item data-layers"}>
                            <a href={hrefLink} title="Add Data Layers" onClick={censusPanelToggle} aria-label="Filter data and add layers to map">
                                <span className="sidebar-item-lbl">Add Data Layers</span>
                                <span className="sr-only">Add Data Layers</span>
                            </a>
                        </li>
                    </ul>
                    <ul className="sidebar-list lower-sidebar">

                        {/* About Modal: Start */}
                        <li className={isModalOpen ? "active sidebar-item about" : "sidebar-item about"}>
                            <a href={hrefLink} title="About" onClick={toggleModalEvt} aria-label="Information about the app">
                                <span className="sidebar-item-lbl">About</span>
                                <span className="sr-only">About</span>
                            </a>
                        </li>
                        {/* About Modal: End */}

                    </ul>
                </Navbar>
            </nav>
            {/* Navigation Links: End */}

            {/* Side Bar Items: Start */}
            <div style = {isLayersOpen || isCensusOpen ? {display: ''} : {display: 'none'}} id="sidebar-outer">
                <InfoModal
                    showModal={isModalOpen}
                    toggleModal={toggleModal}
                    />

                {isLayersOpen ?
                <FilterPanel
                    isAllFiltersDisabled ={props.isAllFiltersDisabled}
                    resetFiltersSF={props.resetFiltersSF}
                    resetFiltersCMF={props.resetFiltersCMF}
                    sfFilterOptions={props.sfFilterOptions}
                    toggleSFFilter={props.toggleSF}
                    cmfFilterOptions={props.cmfFilterOptions}
                    toggleCMFFilter={props.toggleCMF}
                    handleInputChange={props.handleInputChange}
                    isAllFiltersChecked={props.isAllFiltersChecked}
                    /> : <div/>
                }

                {isCensusOpen ?
                <CensusPanel
                    isLayersOpen={isCensusOpen}
                    isCensusDisabled={props.isCensusDisabled}
                    isCensusChecked={props.isCensusChecked}
                    censusOptions={props.censusOptions}
                    toggleCensus={props.toggleCensus}
                    resetCensus={resetCensus}
                    toggleMap={props.toggleMap}
                    handleInputChange = {props.handleInputChange}
                    /> : <div/>
                }

            </div>
            {/* Side Bar Items: End */}

        </div>
    );
}

export default Sidebar;


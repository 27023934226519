/***********************************************************
*
* Search Widget
* Modify search widget functionality
*
***********************************************************/
export const setSearch = (Search, view, focusLayer, countiesLayer) => {
    const getSearchLayers =  [{
        featureLayer: focusLayer,
        placeholder: "Search By Account Name",
        name: "Search By Account Name",
        suggestionTemplate: "{Account_Name}",
        exactMatch: false,
        outFields: ["*"],
        maxSuggestions: 5,
        },
        {
        featureLayer: countiesLayer,
        placeholder: "Search By County",
        name: "Search By County",
        suggestionTemplate: "{NAME}, {STATE_NAME}",
        exactMatch: false,
        outFields: ["*"],
        maxSuggestions: 50
    }];
    
     return new Search({
            view: view,
            allPlaceholder: "Search Organization",
            sources: getSearchLayers,
            includeDefaultSources: false,
            maxSuggestions: 10,
            autoSelect: false
        });
};

//check if search widget is active
export const searchWidgetActive = (searchTerm) => {
    let searchActive;
    searchTerm ? searchActive = true : searchActive = false;
    return searchActive;
};

// add search widget to DOM
export const addSearchWidget = (view, searchWidget) => {
    if(!document.querySelector('.esri-component.esri-search.esri-widget')) {
        view.ui.add(searchWidget, {
            position: 'top-right'
        });
    }
};
/**
 * Developer: Daniel Pegues
 * Company: NTConcepts
 * Email: daniel.pegues@ntconcepts.com
 * Date: 2019-09-25
 * URLs:
 * ArcGIS: https://github.com/Esri/react-arcgis
 * CSV Import: https://stackoverflow.com/questions/53416529/react-import-csv-file-and-parse
 */
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/fontawesome-all.css';
import './assets/css/app.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import configureStore from './store';

import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <Provider store={configureStore()}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register();

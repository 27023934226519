import React from 'react';
import markerDefault from '../../../assets/images/icons/PNG/pin-blue@2x.png';
import markerAccredited from '../../../assets/images/icons/PNG/pin-green@2x.png';

const Legend = () => {
    return(
        <div id="legend">
            <div className="legend-title">
                <h3>Account Type</h3>
            </div>
            <div className="legend-grid">
                <div className="legend-row">
                    <div className="legend-img-cont">
                        <img className="marker" src={markerAccredited} alt="Search Screenshot" />
                    </div>
                    <div className="">
                        <p>Fully Accredited Account</p>
                    </div>
                </div>
                
                <div className="legend-row">
                    <div className="legend-img-cont">
                        <img className="marker" src={markerDefault} alt="Search Screenshot" />
                    </div>
                    <div className="">
                        <p>Non-Accredited Account</p>
                    </div>
                </div>
            </div>
        </div>
        
    );
}
export default Legend;
/***********************************************************
*
* Helper Functions to modify the default setting for pop-ups
*
***********************************************************/

/* Dock popup */
export const dockedPopup = (view) => {
    view.popup.location = view.center;
    view.popup.dockEnabled = true;
    view.popup.dockOptions = {
        position: "top-right",
        buttonEnabled: true,
        breakpoint: false
    };
};

/* Function to set docked pup to be small */
export const dockedPopupSize = (popupSize) => {
    let popupEle = document.querySelector('.esri-popup__main-container');
    let popupEleIsDocked = document.querySelector('.esri-popup--is-docked');
    let size = [];
    
    if (popupEle && popupEleIsDocked) {
        (popupSize === 'small-popup') ? size = ['50%', '15px'] : size = ['100%', '0'];
        
        popupEle.style.height = size[0];
        popupEleIsDocked.style.margin = size[1];
    }
};

/* Defines an action to zoom out from the selected feature */
export const zoomOutAction = {
    // This text is displayed as a tooltip
    title: "Zoom out",
    // The ID by which to reference the action in the event handler
    id: "zoom-out",
    // Sets the icon font used to style the action button
    className: "esri-icon-zoom-out-magnifying-glass"
  };

export const customZoomEvent = (view) => {
    // Adds the custom action to the popup.
    view.popup.viewModel.actions.push(zoomOutAction);
    
    // This event fires for each click on any action
    view.popup.viewModel.on("trigger-action", function(event){
        // If the zoom-out action is clicked, fire the zoomOut() function
        if(event.action.id === "zoom-out"){
            // view.center = [-102.5795, 39.8283];
            view.zoom = view.zoom - 1;
            // view.popup.close();
        }
    });
};

/***********************************************************
*
* Popup Templates
* Modify default popup templates
*
***********************************************************/
/* Corps Accounts Popup Template */
export const accountPopUpTemplate = {
    // autocasts as new PopupTemplate()
    title: "{Account_Name}",
    overwriteActions: true,  //disables zoom to button on pop-up
    actions: [zoomOutAction],
    content: [{
        type: "fields",
        fieldInfos: [
            {
                fieldName: "Account_Name",
                label: "Account Name"
            },
            {
                fieldName: "Accreditation_Status",
                label: "Accreditation Status"
            },
            {
                fieldName: "Primary_Contact",
                label: "Primary Contact"
            },
            {
                fieldName: "Primary_Contact_Email",
                label: "Primary Contact Email"
            },
            {
                fieldName: "Phone",
                label: "Phone"
            },
            {
                fieldName: "Website",
                label: "Website"
            },
            {
                fieldName: "Billing_Street",
                label: "Billing Street"
            },
            {
                fieldName: "Billing_City",
                label: "Billing City"
            },
            {
                fieldName: "Billing_State_Province",
                label: "Billing State Province"
            },
            {
                fieldName: "Billing_Zip_Postal_Code",
                label: "Zip Postal Code"
            },
            {
                fieldName: "Corpsmember_Focus",
                label: "Corpsmember Focus"
            },
            {
                fieldName: "Service_Focus",
                label: "Service Focus"
            },
            {
                fieldName: "Counties_Served",
                label: "Counties Served"
            }
        ]}
    ]
};

/* Counties Served Popup Template */
export const countyPopUpTemplate = (layerName) =>{
    // autocasts as new PopupTemplate()
    return {title: layerName,
    overwriteActions: true,  //disables zoom to button on pop-up
    actions: [zoomOutAction],
    content: [{
        type: "fields",
        fieldInfos: [
            {
                fieldName: "NAME",
                label: "County Name"
            },
            {
                fieldName: "State_Name",
                label: "State"
            }
        ]}
    ]}
};

export const countyCensusPopUpTemplate = (field, totalPopulation, state, county, title) => {
    const countyTitle = "County: ";
    return {
        title: countyTitle.concat("{" + county + "}, {" + state + "}"),
        overwriteActions: true,
        actions: [zoomOutAction],
        content: [{
            type: "fields",
            fieldInfos: [
                {
                    fieldName: field,
                    label: title
                },
                {
                    fieldName: totalPopulation,
                    label: "Total Population of County"
                }
            ]
        }]
    };
};
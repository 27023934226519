/******************************************************************************
 * MAIN DATA HELPER:
 * Function that are reused throughout the application.
 ******************************************************************************/


//Split string by smicolone & unnecessary whitespace
export const getSplitStringBySemicolone = function(dataArr){
  // should return an array -> ["King, WA", "Pierce, WA", "Snohomish, WA", "Skagit, WA"]
  return dataArr.split(';');
};

//Split array by smicolone & unnecessary whitespace
export const getSplitArrBySemicolone = function(dataArr){
  return dataArr.map((x) => x.split(";")).flat().map(ele => ele.trim());
};

//Split array by commas & remove unnecessary whitespace
export const getSplitArrByCommma = function(dataArr){
  return dataArr.map((x) => x.split(",")).flat().map(ele => ele.trim());
};

//Get unique elements from an array 
export const getUniques = function(dataArr){
  return dataArr.filter((x, i, a) => a.indexOf(x) === i && a[i] !== "" && a[i] !== "."  && a[i] !== undefined && a[i] !== null);;
};

//Change array elements to lower case
export const arrEleToLowerCase = function(arr){
  return arr.map((x) => x.toLowerCase());
};

//Get elements that are the same in two arrays.
export const getArrDiff = function(arr1, arr2){
  let arrDiff = [];

  for(let i in arr1) {
      if(arr2.indexOf(arr2[i]) > -1){
          arrDiff.push(arr1[i]);
      }
  }

  return arrDiff;
};

export const isEmpty = (obj) => {
    return JSON.stringify(obj) === '{}';
};


import React from 'react';
import Filter from './Filters';

const FilterPanel = (props) => {
    const {
        resetFiltersCMF,
        resetFiltersSF,
        isAllFiltersChecked,
        handleInputChange,
        isAllFiltersDisabled
    } = props;

    return(
        <div className="sidebar-container toggle">
            <div className="filters-panel">
                <div className= "filters-header">
                    <div>
                        <h2>Filters</h2>
                    </div>
                    <div id="all-filters-cont">
                        <input
                            type="checkbox"
                            id="focus-filters-checkbox"
                            className="all-filters-checkbox"
                            name="isAllFiltersChecked"
                            checked={isAllFiltersChecked}
                            disabled={isAllFiltersDisabled}
                            onChange={handleInputChange}
                            />
                        <label htmlFor="all-filters-checkbox">
                            <span className="lbl">All</span>
                            <span className="toggle"></span>
                        </label>
                    </div>
                </div>
                <div className="main-cont-overflow">
                    <div className="category-container service-focus">
                        <div className="category-header">
                            <h3>Service Focus</h3>
                        </div>
                        
                        <div className="filters-container">
                            <Filter
                                filterOptions={props.sfFilterOptions}
                                toggleFilter={props.toggleSFFilter}
                                />
                        </div>
                        
                        <button
                            type="button"
                            className="button"
                            title="Click to reset selection of Service Focus"
                            alt="Click to reset selection of Service Focus"
                            onClick={() => resetFiltersSF()}
                            >
                            Reset
                        </button>
                    </div>
                    
                    <div className="category-container corpsmember-focus">
                        <div className= "category-header">
                            <h3>Corpsmember Focus</h3>
                        </div>
                        
                        <div className="filters-container">
                            <Filter
                                filterOptions={props.cmfFilterOptions}
                                toggleFilter={props.toggleCMFFilter}
                                />
                        </div>
            
                        <button
                            type="button"
                            className="button"
                            title="Click to reset selection of Corpsmember Focus"
                            alt="Click to reset selection of Corpsmember Focus"
                            onClick={() => resetFiltersCMF()}
                            >
                            Reset
                        </button>
                    </div>
                </div>
            </div>
        </div>
        );
    }

export default FilterPanel;
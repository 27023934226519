import React from 'react';
import esriLogo from "../../../assets/images/logos/esri-logo.jpeg";

const FooterCopyright = () => {
    return (
        <div id='footer-copyright'>
            <p>This application is Powered by Esri with the use of Garmin, FAO, NOAA, EPA. For more information about Esri Proprietary Rights Acknowledgment go to <a className='about-link' href="https://www.esri.com/en-us/legal/copyright-trademarks" target="_blank" rel="noopener noreferrer">Esri Legal</a>.</p>
            
            <a href="https://www.esri.com/en-us/arcgis/about-arcgis/overview" rel="noopener noreferrer" target="_blank">
                <img src={esriLogo} alt="Esri Logo" className='esri-logo' />
            </a>
        </div>
    );
};

export default FooterCopyright;

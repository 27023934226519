import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';

import './App.css';

import { simpleAction } from './actions/simpleAction';
import Dashboard from './components/Dashboard';
import Layout from './components/Layout';
import Header from './components/Header';

// Map State to Props
const mapStateToProps = state => ({
    ...state
});

// Map Dispatch to Props
const mapDispatchToProps = dispatch => ({
    simpleAction: () => dispatch(simpleAction())
});

//simpleAction = (event) => {
    //this.props.simpleAction();
//}

function App() {
    return (
        <Layout>
            <Header />

            <Container id="content" className="content">
                <main id="main" className="main" aria-label="Main Content" role="main">
                    <Dashboard />
                </main>
            </Container>
        </Layout>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
import React from 'react';

const Filters = (props) => {

    const { filterOptions, toggleFilter } = props;

    const options = filterOptions.map((option) => 
        <li id={option.id} className={option.active ? "filter-selected" : "filter-unselected"} key={option.id}>
            <span onClick={() => toggleFilter(option)}>
                {option.value}
            </span>
        </li>
    );

    return (
        <div id="filters">  
            <ul className="filter-list">
                {options}
            </ul>
        </div>
    )

}

export default Filters;
/******************************************************************************
 * ****************************************************************************
 *  Child Component: CensusOptions
 *  CensusPanel is its parent component
 *  Functionality: Populate the census panel with the census fields and subfields
 *
 *  States: no states
 *  Props:
 *  censusOptions - passes the census data
 *  toggleCensus - toggle for outer census fields
 *  toggleMap - toggle for inner census subfields
 * ***************************************************************************
******************************************************************************/
import React from 'react';

const CensusOptions = (props) => {

    const { censusOptions, toggleCensus, toggleMap } = props;
    
    const options = censusOptions.map((option, i) =>
        <div key={i}>
            <div onClick={() => toggleCensus(option, i)} key={i} className={option.active ? "demographic-title filter-selected" : "demographic-title"}>
                <li id={option.id} key={option.id}>
                    <span>
                        {option.primaryFieldDisplay}
                    </span>
                </li>
             </div>
            {option.secondaryFields && option.secondaryFields.length > 0 && option.active &&
                <ul>
                    {option.secondaryFields.map((option2, j) =>
                        <li id={option2.id} className={option2.active ? "filter-selected" : "filter-unselected"} key={option2.id}>
                            <span onClick={() => toggleMap(i, j, option2)} >
                                {option2.display}
                            </span>
                        </li>
                    )}
                </ul>
            }
        </div>
    );

    return (
        <div id="filters">
            <ul className="filter-list census-demographics-list">
                {options}
            </ul>
        </div>
    )

}

export default CensusOptions;
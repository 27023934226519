/******************************************************************************
 *  ArcGis Widgets Helper
 *  Helper for function being reused in the overall application
******************************************************************************/

import markerSelected from '../../../assets/images/icons/PNG/pin-orange@2x.png';
import markerDefault from '../../../assets/images/icons/PNG/pin-green@2x.png';
import markerAccreditedDefault from '../../../assets/images/icons/PNG/pin-blue@2x.png';


/***********************************************************
*
* Legend
* Modify Legend
*
***********************************************************/
export const setCensusLegend = (view, Legend, censusCountiesLayer, mapState) => {
    return  new Legend({
        view: view,
        layerInfos: [
            {
                layer: censusCountiesLayer,
                title: mapState.title
            }
        ]
    });
};

/***********************************************************
*
* Picture Markers
* Modify default picture markers to be costume to the client
*
***********************************************************/

/* Marker: Default */
export const pictureMarkerDefault = {
    type: 'picture-marker',
    url: markerDefault,
    width: "20px",
    height: "25px" 

};

/* Marker: Selected */
export const pictureMarkerSelected = {
    type: 'picture-marker',
    url: markerSelected,
    width: "22px",
    height: "36px"
};

/* Marker: Accredited */
export const pictureMarkerAccredited = {
    type: 'picture-marker',
    url: markerAccreditedDefault,
    width: "20px",
    height: "25px"
};

/* When account is accredited show blue pin, otherwise show green */
export const setAccreditationStatusPinDefault = (pinDataObj) => {
    (pinDataObj.attributes.Accreditation_Status === 'Fully Accredited') ?
        pinDataObj.symbol = pictureMarkerAccredited
        : pinDataObj.symbol = pictureMarkerDefault;
    return pinDataObj;
};

/* When account is accredited show blue pin, otherwise show green */
export const setAccreditationStatusPinSelected = (pinDataObj) => {
    pinDataObj.symbol = pictureMarkerSelected;
    return pinDataObj;
};

/***********************************************************
*
* Picture Markers
* Modify default picture markers to be costume to the client
*
***********************************************************/

/* Default counties fill color */
export const countyFillSymbol = {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [218, 224, 224, 0.8],
    outline: { // autocasts as new SimpleLineSymbol()
        color: [255, 255, 255],
        width: 0.05,
    }
};

/* Fill polygon - fill county */
export const censusDataCountyFillSymbol = {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [0,0, 0, 1],
    outline: { // autocasts as new SimpleLineSymbol()
        color: [255, 255, 255],
        width: 0.05,
    }
};

/* FILL SYMBOLS FOR HIGLIGHTING RELATED COUNTIES */
/* Set unselected related counties fill color */
export const relatedCountyFillSymbol = {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [111, 142, 164],
    outline: { // autocasts as new SimpleLineSymbol()
        color: [255, 255, 255],
        width: 0.05,
    }
};

/* FILL SYMBOLS FOR HIGLIGHTING RELATED COUNTIES */
/* Set selected related counties fill color */
export const selectedCountiesFillSymbol = {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [120, 142, 142],
    outline: { // autocasts as new SimpleLineSymbol()
        color: [96, 115, 115],
        width: 1,
    }
};

/*
* Add Thematic mapping for census data.
* @param {string} field - The name of the numeric attribute field that contains the data values used to determine the color of each feature
* @param {string} normalizationField - Name of the numeric attribute field by which to normalize the data. If this field is used, then the values in stops should be normalized as percentages or ratios.
*
* @return {object}  testCensus - returns object to visualize the thematic mapping
* */
export const setCensusLayerThematicMap = function(field, normalizationField, title, stops) {
    return{
        type: "simple", // autocasts as new SimpleRenderer()
        symbol: censusDataCountyFillSymbol,
        label: "U.S. County",
        visualVariables: [
        {
            type: "color",
            field: field,
            normalizationField: normalizationField,
            legendOptions: {
                title: title,
            },
            stops: stops
        }
        ]
    };
};
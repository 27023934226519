import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Row, Col } from 'reactstrap';
import classnames from 'classnames';

import ArcGISAboutInfo from "./ArcGISAboutInfo";
import FooterCopyright from "./FooterCopyright";

const InfoModal = (props) => {
    const { showModal, toggleModal } = props;
    const [activeTab, setActiveTab] = React.useState('1');
    
      const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
      };
      
    return (
        <div id='main-modal'>
            <Modal 
                size='lg'
                centered={true} 
                scrollable={true} 
                isOpen={showModal} 
                toggle={toggleModal} 
                aria-labelledby="About the application">
                
                <div className='modal-header'>
                    <h1>Additional Information</h1>
                </div>
                
                <ModalBody>
                    <div>
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                          >
                            About The Corps Network
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                          >
                            Map Features
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <Row>
                            <Col sm="12">
                                <div className="about-corps">
                                    <h2> About The Corps Network </h2>
                                    <p>
                                        The Corps Network, the national association of Service and Conservation Corps, provides leadership and support to over 130 Corps across the United States. Through advocacy, and providing Corps access to funding opportunities and expert guidance, The Corps Network annually enables more than 25,000 Corpsmembers to strengthen communities, improve the environment and transform their lives through service. To learn more about The Corps Network, please visit <a className='about-link' href="www.corpsnetwork.org." rel="noopener noreferrer" target="_blank">www.corpsnetwork.org.</a>
                                    </p>
                                    <p>
                                        Corps are locally-based organizations that engage young adults (generally ages 16 – 25) and veterans (up to age 35) in service projects that address recreation, conservation, disaster response, and community needs. Through a term of service that could last from a few months to a year, Corps participants – or “Corpsmembers” – gain work experience and develop in-demand skills. Corpsmembers are compensated with a stipend or living allowance and often receive an education award or scholarship upon completing their service. Additionally, Corps provide participants educational programming, mentoring, and access to career and personal counseling. Some Corps operate or partner with charter schools to help participants earn their high school diploma or GED.
                                    </p>
                                </div>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="2">
                          <Row>
                            <Col sm="12">
                              <ArcGISAboutInfo />
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </div>
                    <FooterCopyright/>
                </ModalBody>
                
                <ModalFooter>
                    <Button onClick={toggleModal}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}  

export default InfoModal;
/******************************************************************************
 * ****************************************************************************
 *  Child Component: CensusPanel
 *  Sidebar is its parent component
 *  Functionality: show the panel for Census fields
 *
 *  States: no states
 *  Props:
 *  isCensusChecked - check if the census layer toggle button is checked
 *  handleInputChange - check when a checkbox is clicked
 *  isCensusDisabled - check if the census layer toggle button is disabled
 *  resetCensus - resets census layers
 * ***************************************************************************
******************************************************************************/

import React from 'react';
import CensusOptions from './CensusOptions';

const CensusPanel = (props) => {
    const {
        isCensusChecked,
        handleInputChange,
        isCensusDisabled,
        resetCensus
    } = props;

    
    return (
        <div className="sidebar-container toggle">
            <div className="filters-panel">
                <div className="filters-header census-header">
                    <div>
                        <h2>Census Data</h2>
                    </div>
                </div>
                <div className="main-cont-overflow">
                    <div className="category-container census-demographics-cont">
                        <div>
                            <div id="all-filters-cont" className="census-toggle-cont">
                                <input
                                    type="checkbox"
                                    id="census-checkbox"
                                    className="all-filters-checkbox"
                                    name="isCensusDataChecked"
                                    checked={isCensusChecked}
                                    disabled={isCensusDisabled}
                                    onChange={handleInputChange}
                                />
                                <label htmlFor="all-filters-checkbox">
                                    <span className="lbl">Turn Off Census Layer</span>
                                    <span className="toggle"></span>
                                </label>
                            </div>
                        </div>
                        <div className="category-header">
                            <h3>Census Demographics</h3>
                        </div>
                        <div className="filters-main-cont">
                            <div className="filters-container">
                                <CensusOptions
                                    censusOptions={props.censusOptions}
                                    toggleCensus={props.toggleCensus}
                                    toggleMap={props.toggleMap}
                                />
                            </div>
                             <button
                                type="button"
                                className="button"
                                title="Click to deselect Census layer"
                                alt="Click to deselect Census layer"
                                onClick={() => resetCensus()}
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CensusPanel;
/******************************************************************************
 * ****************************************************************************
 *  Child Component: Logo
 *
 *  Load and display company logo
 * ***************************************************************************
******************************************************************************/

import React from 'react';
import { connect } from 'react-redux';

import logo from '../../assets/images/logos/corps_network_logo.svg';

class Logo extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            mounted: false
        };
    }

    componentDidMount(){
        this.setState({
            mounted: true
        });
    }
    componentDidUpdate(){}
    componentWillUnmount(){
        this.setState({
            mounted: false
        });
    }

    render(){
        return(
            <div className="logo">
                <img src={logo} alt="Corp Network Logo" />
            </div>
        );
    }
}

export default connect()(Logo);
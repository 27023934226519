import React from 'react';

import searchWidgetImg from "../../../assets/images/about/search-widger-screenshot.jpg";
import poupImg from "../../../assets/images/about/poup-screenshot.jpg";
import poupDockedImg from "../../../assets/images/about/popup-docked-screenshot.jpg";

import Legend from "./Legend";

const ArcGISAboutInfo = () => {
    return (
        <div className='about-modal'>
            <div className='features'>
                <h2>Map Features</h2>
                
                <p>This application was made using Esri ArcGis API services and utilizes features used in the ArcGis platform in order to control the map. The features include, but are not limited to:</p>
                
                <div className="modal-info-grid">
                    
                    <Legend/>
                    
                    <div className="modal-info-grid-row">
                        <div className="modal-info-grid-col-12">
                            <h3>Zoom in/out</h3>
                            
                            <p>The Zoom widget allows users to zoom in/out within a view. It is located at the bottom of the sidebar.</p>
                        </div>
                    </div>
                    
                    <div className="modal-info-grid-row">
                        <div className="modal-info-grid-col-6">
                            <h3>Search</h3>
                            
                            <p>The Search widget enables end users to find locations or search features on the map based on the Account Name or County.</p>
                            
                            <p>When you enter a place-name or a keyword in the search box, you see suggestions as you type. If you see an arrow in the search box, you can search on all sources, or click the arrow and choose a locator or layer from the drop-down list.</p>
                        </div>
                        <div className="modal-info-grid-col-6">
                            <img src={searchWidgetImg} alt="Search Screenshot" />
                        </div>
                    </div>
                    
                    <div className="modal-info-grid-row">
                        <div className="modal-info-grid-col-6">
                            <h3>Popup</h3>
                            
                            <p>A map can show descriptive information about features configured to display in a pop-up. Pop-ups bring to life the attributes associated with each feature layer in the map, such as account name, accreditation status, primary contact, primary contact email, phone, website, billing street, billing city, billing state province, zip postal code, corpsmember focus, service focus, and counties served.</p>
                        </div>
                        <div className="modal-info-grid-col-6">
                            <img src={poupImg} alt="Popup Screenshot" />
                        </div>
                    </div>
    
                    <div className="modal-info-grid-row">
                        <div className="modal-info-grid-col-12">
                            <p>To view a bigger version of the popup, click the dock icon located in the top right and the popup will dock to the right of the map.</p>
                        </div>
                    </div>
                    
                    <div className="modal-info-grid-row">
                        <div className="modal-info-grid-col-12">
                            <img src={poupDockedImg} alt="Docked popup screenshot" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ArcGISAboutInfo;

/* *****************************************************************************
 * ****************************************************************************
 *  Feature Layer:
 *  TEST CENSUS DATA FEATURE LAYER
 * ***************************************************************************
******************************************************************************/

import * as ArcGISWidgetHelper from "./arcgisWidgetHelper";
import {countyPopUpTemplate, countyCensusPopUpTemplate, accountPopUpTemplate} from "./arcgisPopupWidgetHelper";

export const setCensusFeatureLayer = (mapState, FeatureLayer) => {
    return new FeatureLayer({
        url: mapState.url,
        outFields: [mapState.variable, mapState.totalPopulation],
        renderer: ArcGISWidgetHelper.setCensusLayerThematicMap(mapState.variable, mapState.normalization, mapState.description, mapState.stops),
        popupTemplate: countyCensusPopUpTemplate(mapState.variable, mapState.totalPopulation, mapState.state, mapState.county, mapState.title)
    });
};

export const setFeatureLayer = (layer, apiUrl, FeatureLayer) => {
    let featureLayer;
    switch (layer) {
        case "Related Counties":
            featureLayer = new FeatureLayer({
                url: apiUrl,
                renderer: {
                    type: "simple",
                    symbol:ArcGISWidgetHelper.relatedCountyFillSymbol
                },
                popupTemplate: countyPopUpTemplate("Related County: {NAME}"),
            });
            break;
        case "Served Counties":
            featureLayer = new FeatureLayer({
                url: apiUrl,
                outFields: ["NAME", "STATE_NAME"],
                renderer: {
                    type: "simple",
                    symbol: ArcGISWidgetHelper.countyFillSymbol
                },
                popupTemplate: countyPopUpTemplate("Served County: {NAME}")
            });
            break;
        case "Crops Accounts":
            featureLayer = new FeatureLayer({
                url: apiUrl,
                outFields: ['*'],
                popupTemplate: accountPopUpTemplate
            });
            break;
        default:
            featureLayer = '';
    }
    
    return featureLayer
};


/******************************************************************************
 * CSV DATA HELPER:
 * This data helper file is used to help format the data getting form a csv file.
 ******************************************************************************/

import nextId from 'react-id-generator';
import { getSplitArrBySemicolone, getUniques } from './dataHelper';

// Check if the is data available
function isDataAvailable(dataArr){
    let allFilters = [];

    if (Array.isArray(dataArr) &&  dataArr.length && dataArr[0] !== undefined) {
        allFilters = getSplitArrBySemicolone(dataArr);
    } else {
        allFilters = ["No Data Available"];
    }

    return allFilters;
}

// Format array to contain an array of objects for multiselect
function getMultiselectArr(dataArr) {
    let formattedArray = [];  
  
    dataArr.map((ele) =>
      formattedArray = [...formattedArray, {id : nextId(), value : ele, active : false}]);
  
    return formattedArray;
  }

// Remove white spaces from object key
export const replaceKeys = function(object){
  Object.keys(object).forEach(function (key) {
      var newKey = key.replace(/\s+/g, '');
      if (object[key] && typeof object[key] === 'object') {
          replaceKeys(object[key]);
      }
      if (key !== newKey) {
          object[newKey] = object[key];
          delete object[key];
      }
  });
}

// Get unique filters
export const getFilters = function(data){
  const formattedArray = isDataAvailable(data),
  uniqueFilters = getUniques(formattedArray);

  return getMultiselectArr(uniqueFilters);
};
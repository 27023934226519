import React from 'react';

class Layout extends React.Component {
    state = {
        mounted: false,
    }

    componentDidMount() {
        this.setState({ mounted: true });
    }

    componentWillUnmount() {
        this.setState({ mounted: false });
    }

    render() {
        return (
            <div id="wrapper" className="wrapper" aria-label="Wraps Application">
                {this.props.children}
            </div>
        )
    }
}
export default Layout;
/******************************************************************************
 * ****************************************************************************
 *  Child Component: Header
 *  This component draws the header for the application.
 *  Dashboard is the parent component
 *
 * Props: no props.
 * State: no state
 * ***************************************************************************
******************************************************************************/

import React from 'react';
import { connect } from 'react-redux';

import Logo from './Header/Logo';

class Header extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            mounted: false
        };
    }

    componentDidMount(){
        this.setState({
            mounted: true
        });
    }
    componentDidUpdate(){}
    componentWillUnmount(){
        this.setState({
            mounted: false
        });
    }
    
    render(){
        return(
            <header id="header" className="header" role="banner">
                <div className="header-inner">
                    <Logo />
                </div>
            </header>
        );
    }
}

export default connect()(Header);
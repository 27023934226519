/******************************************************************************
 * ****************************************************************************
 *  Feature Layer:
 *  This component is in charge of drawing the layers on the map.
 *  Dashboard is the parent component
 *
 *  Currently there are two layers:
 *  1. countiesLayer: displays the shape file for the related counties per account
 *  2. focusLayer: display all corps network accounts
 *
 * This component uses hooks to get the filter data.
 *
 * States: No states
 * Props:
 * map, view - ArcGIS to display map
 * sfFilters - Service Focus filters
 * cmfFilters - Corpsmembers Focus filters
 * focusAPIUrl - API URL for the corps network account information
 * countyAPIUrl - API URL for all counties in the US
 * ***************************************************************************
******************************************************************************/

import React from 'react';
import { loadModules } from '@esri/react-arcgis';

/*Import functions*/
import * as ArcGISWidgetHelper from "./data-helpers/arcgisHelpers/arcgisWidgetHelper";
import * as ArcGISFeatureLayerHelper from "./data-helpers/arcgisHelpers/arcgisFeatureLayersHelper";
import * as ArcGISSearchWidgetHelper from "./data-helpers/arcgisHelpers/arcgisSearchWidgetHelper";
import * as ArcGISPopUpWidgetHelper from "./data-helpers/arcgisHelpers/arcgisPopupWidgetHelper";

import { getCountiesServed, getArcgisQuery, getRelatedCountiesServed } from "./data-helpers/countiesServedDataHelper";
import { isEmpty } from "./data-helpers/dataHelper"

const getPartialQueryString = (elementArr, fieldName) => {
    if (elementArr.length === 0){
        return null
    }

    const formattedArr = elementArr.map((element, index, {length}) => {
        const query = `${fieldName} LIKE '%${element.value}%'`
        return (index + 1 === length) ? query : query + " OR "
    });

    return formattedArr.join(' ');
};

const getQueryString = (sf, cmf) => {
    const sfClause = getPartialQueryString(sf, "Service_Focus");
    const cmfClause = getPartialQueryString(cmf, "Corpsmember_Focus");

    if (sfClause !== null && cmfClause !== null) {
        return `${sfClause} AND ${cmfClause}`
    } else if(sfClause !== null) {
        return `${sfClause}`
    } else if(cmfClause !== null) {
        return `${cmfClause}`
    } else {
        // THIS SHOULDN'T BE POSSIBLE
        return ""
    }
};

let censusLegend = null;

/*MAIN: Set the feature layers on the map*/
const FeatureLayers = (props) => {
    const { 
        map,
        view,
        sfFilters,
        cmfFilters,
        isCensusChecked,
        mapState
    } = props;
    
    const focusAPIUrl = 'https://services8.arcgis.com/WEHIwhoIdsY6m6Wc/arcgis/rest/services/All_Corps_Members_Data/FeatureServer';
    const countyAPIUrl = 'https://services.arcgis.com/P3ePLMYs2RVChkJx/arcgis/rest/services/USA_Counties/FeatureServer';
    
    const [currentGraphics, setCurrentGraphic] = React.useState({});
    
    React.useEffect(() => {
        
        loadModules([
            'esri/Map', 
            'esri/layers/FeatureLayer', 
            'esri/Graphic', 
            'esri/widgets/Search',
            'esri/widgets/Legend',
            'esri/core/watchUtils'
        ]).then(([ Map, FeatureLayer, Graphic, Search, Legend, watchUtils ]) => {

                map.removeAll();
                view.graphics.removeAll();
                
                if (censusLegend != null) {
                    view.ui.remove(censusLegend); //Removes previous legends so that they don't stack on the screen
                }
                
                const focusLayer = ArcGISFeatureLayerHelper.setFeatureLayer('Crops Accounts', focusAPIUrl, FeatureLayer);
                const countiesLayer = ArcGISFeatureLayerHelper.setFeatureLayer('Served Counties', countyAPIUrl, FeatureLayer);
                const relatedCountiesLayer = ArcGISFeatureLayerHelper.setFeatureLayer('Related Counties', countyAPIUrl, FeatureLayer);
                
                const searchWidget = ArcGISSearchWidgetHelper.setSearch(Search, view, focusLayer, countiesLayer);
                
                const focusLayerQuery = focusLayer.createQuery();
                focusLayerQuery.where = getQueryString(sfFilters, cmfFilters);
                focusLayerQuery.outFields = ['*'];
            
                focusLayer.queryFeatures(focusLayerQuery).then(function(focusLayerResponse){
                    drawMarkers(focusLayerResponse, Graphic); //set accounts pins in map
                    setCountiesServedLayer(focusLayerResponse, countiesLayer); //set counties served borders in map
                });
                
                ArcGISSearchWidgetHelper.addSearchWidget(view, searchWidget); // Add Search Widget to view
                ArcGISPopUpWidgetHelper.customZoomEvent(view); //custom zoom event for popup
            
                view.on("click", function (event) { //hitTest used to create a selection graphic for the popup
                    onClickHandler(event, relatedCountiesLayer, searchWidget) //change marker for account to be darker and bigger
                });
                
                //List of markers without the searched pin
                searchWidget.on("search-complete", function(event){
                    searchWidgetResult (event);
                });
  
                // Event for clearing the searchbar
                searchWidget.on("search-clear", function(event){
                    resetMap();
                });
                
                //if current graphic is not empty, set related counties to current pin
                if (!isEmpty(currentGraphics)) {
                    setRelatedCountiesLayer(currentGraphics, relatedCountiesLayer);
                    view.popup.open({
                        popupTemplate : ArcGISPopUpWidgetHelper.accountPopUpTemplate,
                        features: [currentGraphics]
                    });
                }
                
                if (mapState.url.length > 0){ setCensusLayer(Legend, FeatureLayer); } //if url is not empty, set census layer
                
                let dockedPopupSize = setDockedPopupSize();
                watchUtils.whenTrue(view.popup, "visible", function(visible) {
                    // setPopupSetting();
                    ArcGISPopUpWidgetHelper.dockedPopup(view);
                    ArcGISPopUpWidgetHelper.dockedPopupSize(dockedPopupSize);
                });
                
        }).catch((err) => console.error(err));
    }, [sfFilters, cmfFilters, isCensusChecked, currentGraphics]);
    
    /* if census layer is on, change size of docked popup */
    const setDockedPopupSize = () => {
        let dockedPopupSize = '';
        (isCensusChecked) ? dockedPopupSize = 'small-popup' : dockedPopupSize = 'full-popup';
        return dockedPopupSize;
    };
    
    /* set county layer on map*/
    const setCensusLayer = (Legend, FeatureLayer) => {
        if (censusLegend != null) {
            view.ui.remove(censusLegend); //Removes previous legends so that they don't stack on the screen
        }
        
        const censusCountiesLayer = ArcGISFeatureLayerHelper.setCensusFeatureLayer(mapState, FeatureLayer);
        censusLegend = ArcGISWidgetHelper.setCensusLegend(view, Legend, censusCountiesLayer, mapState);
       
        map.layers.push(censusCountiesLayer);  // add census layer to map
        map.reorder(censusCountiesLayer, 1);  // move layer to be in bottom
        view.ui.add(censusLegend, "bottom-right"); // add legend
    };
    
    /* Set only the counties serve by account */
    const setCountiesServedLayer = (focusLayerResponse, countiesLayer) => {
        let countiesServedArr = [];
        let countiesQuery = '';
        
        //saved counties served into array
        focusLayerResponse.features.forEach(f => {
            if (f.attributes.Counties_Served != null){
                countiesServedArr.push(f.attributes.Counties_Served)
            }
        });
        
        //Get query string for counties served
        const county = getCountiesServed(countiesServedArr);
        countiesQuery = getArcgisQuery(county);
    
        //set served counties layer
        countiesLayer.definitionExpression = countiesQuery;
        map.layers.push(countiesLayer);
        map.reorder(countiesLayer, 0);
    };
    
    /**
     * Description. add layer with related counties
     */
    const setRelatedCountiesLayer = (resultGraphic, relatedCountiesLayer) => {
        let relatedCountiesQuery = '';
        const stringCountiesServed = resultGraphic.attributes.Counties_Served;
        if (stringCountiesServed) {
            //Get query string for counties served
            const countyServedArr = getRelatedCountiesServed(stringCountiesServed);
            relatedCountiesQuery = getArcgisQuery(countyServedArr);
            relatedCountiesLayer.definitionExpression = relatedCountiesQuery;
            
            map.layers.push(relatedCountiesLayer);
            map.reorder(relatedCountiesLayer, 2);
        } else {
            map.remove(relatedCountiesLayer);
        }
    };
    
   /* Set the markers for each account on the map */
    const drawMarkers = (data, Graphic) => {
        let graphicsList = [];
        for(let i=0, len = data.features.length; i < len; i++){
            const graphic = new Graphic({
                geometry: data.features[i].geometry,
                attributes: data.features[i].attributes,
                popupTemplate: ArcGISPopUpWidgetHelper.accountPopUpTemplate
            });
            
            if (isEmpty(currentGraphics)) {
                graphicsList.push(ArcGISWidgetHelper.setAccreditationStatusPinDefault(graphic));
            } else {
                const updatedGraphic = updateGraphic(graphic);
                graphicsList.push(updatedGraphic);
            }
        }
        
        view.graphics.addMany(graphicsList);
    };
    
    /**
     * Description. when a pin is selected it updates the marker to look different from others
     */
    const updateGraphic = (graphic) => {
        let mapPin;
        (currentGraphics.attributes.Account_Name === graphic.attributes.Account_Name) ?
            mapPin = ArcGISWidgetHelper.setAccreditationStatusPinSelected(graphic)
            : mapPin =  ArcGISWidgetHelper.setAccreditationStatusPinDefault(graphic);
        return mapPin;
    };
    
    /**
     * Description. returns pin on map to its default state
     */
    const onClickHandler = (event, relatedCountiesLayer, searchWidget) => {
        view.hitTest(event.screenPoint)
            .then(response => {
                const [firstResult] = response.results;
                const graphic = firstResult.graphic;

                handleLayer(graphic, relatedCountiesLayer, searchWidget);
            });
    };
    
    /*
    * Description. Handle layer that being clicked on.
    * Check if there is layer.
    * */
    const handleLayer = (graphic, relatedCountiesLayer, searchWidget) => {
        const { type } = graphic.geometry || '';
        const searchActive = ArcGISSearchWidgetHelper.searchWidgetActive(searchWidget.searchTerm);
        
        if (!graphic.geometry) {
            searchActive ?  searchWidget.clear() :  resetMap();
            return false;
        }
        
        (type === 'point') ?
            handlePoint(graphic)
        :
            view.center = graphic.geometry.centroid;
    };
    
    const handlePoint = (graphic) => {
        setCurrentGraphic(graphic);
        view.zoom = 6; //zoom to marker
        view.center = graphic.geometry; //center view into marker
    };

    const handlePolygon = (graphic) => {
        graphic.symbol = ArcGISWidgetHelper.selectedCountiesFillSymbol;
        view.graphics.add(graphic);
        view.zoom = 6; //zoom to marker
        view.center = graphic.geometry.centroid; //center view into marker
    };
    
    /**
     * Description. Watch for popup visibility, if popup is visible add costume features
     */
    // const setPopupSetting = () => {
        // ArcGISPopUpWidgetHelper.dockerPopup(view);
        
        // if popup close button is selected, return pin to default view and remove related counties layer
        // document.addEventListener('click', function(e) {
        //     const target = e.target;
        //     if (target.title === 'Close' || target.className === 'esri-popup__icon esri-icon-close'){
        //         resetMap();
        //     }
        // }, false);
    // };
    
    /* *
    * Description. Add to map search result
    * If the search result is a Corps account then add an orange pin, otherwise dont add a pin
    * */
    const searchWidgetResult = (event) => {
        const eventFeature = event.results[0].results[0].feature;
        const markersList = view.graphics;
        const foundGraphic = markersList.find(marker => marker.attributes.Account_Name === event.searchTerm);
        
        (eventFeature.geometry.type === 'polygon') ? handlePolygon(eventFeature) : handlePoint(foundGraphic);
    };
    
    const resetMap = () => {
        setCurrentGraphic({});
    };
    
    return <div/>;
};

export default FeatureLayers;
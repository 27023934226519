/******************************************************************************
 * COUNTIES SERVED DATA HELPER:
 * This data helper file is used to help format the data getting form API.
 ******************************************************************************/

import { convertRegion } from './stateHelper';
import { getSplitStringBySemicolone, getSplitArrBySemicolone, getSplitArrByCommma, getUniques, arrEleToLowerCase } from './dataHelper';

//Get all states from array
function getStates(arr){
    let statesArr = [];
    
    arr.forEach(ele => {
        if(arr.indexOf(ele) % 2 !== 0) {
            statesArr.push(ele)
        }
    });
    
    return statesArr;
}

/**
 * Create an array of obj with all unique U.S states.
 * i.e. 
 * obj = [{
 *      state: 'New York',
 *      county: []
 *  },{
 *      state: 'New Jersey',
 *      county: []
 *  },
 * ] 
 * 
 * @param {*} arr //receives an array of U.S states
 */
function getObj(arr){
    let result = [];
    
    arr.forEach(ele => {
      let obj = {};
      obj['state'] = ele;
      obj['county'] = [];
      result.push(obj);
    });
  
    return result;
  }

/**
 * Create an array of objs where counties relate to one state.
 * i.e. obj = [{
 *  state: 'New York',
 *  county: ['Rockland', 'New York', 'Westchester', 'sullivan']
 * }]
 * 
 * @param {*} obj //receives obj with state
 * @param {*} arr //receives arr with all data
 */
function getCountiesServedObj(obj, arr){      
    for (let i = 0; i < arr.length; i++){
        if(i % 2 !== 0){
            obj.forEach(ele => {
                if (ele.state === arr[i]){
                    ele.county.push(arr[i-1])
                }
            })
        }
    }
    return obj;
}

//Format data to be a valid sql query.
//i.e. "STATE_NAME = 'New Jersey' AND NAME = 'bergen' OR STATE_NAME = 'New York' AND NAME IN ('Rockland', 'New York', 'Westchester', 'sullivan')"
function getFormattedQuery(obj){
    let states = ''; 
    
    obj.forEach(ele => {
        states += ("STATE_NAME = '" + convertRegion(ele.state, 1) + "' AND NAME IN (" + ele.county.map(ele => "'" + ele + "'") + ") OR ")
    });

    states = states.substring(0, states.length - 4);
    
    return states;
}

// Get array of counties served
export const getCountiesServed = function(data){
    const formattedArr = getSplitArrBySemicolone(data),
    arrUniqueElements = getUniques(formattedArr);
    
    return arrEleToLowerCase(arrUniqueElements);
};

//Get related counties
export const getRelatedCountiesServed = function(data){
    const formattedArr = getSplitStringBySemicolone(data),
    arrUniqueElements = getUniques(formattedArr);

    return arrEleToLowerCase(arrUniqueElements);
};

// Get a string query to be use for county served layer
export const getArcgisQuery = function(countiesServed){
    const splitArr = getSplitArrByCommma(countiesServed),
    statesArr = getStates(splitArr),
    uniqueStateArr = getUniques(statesArr),
    unqueStatesObj = getObj(uniqueStateArr),
    countyServedObj = getCountiesServedObj(unqueStatesObj, splitArr);
    
    return getFormattedQuery(countyServedObj);
};
 
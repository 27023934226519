/******************************************************************************
 * ****************************************************************************
 *  Main Component: Dashboard
 *  This component is the parents for all other components.
 *  This component uses hooks to set the filter data.
 *
 * Props: no props.
 *
 * State:
 * focusAPIUrl - set state for API URL for the corps network account information
 * countyAPIUrl - set state for API URL for all counties in the US
 * map, view - ArcGIS to display map
 * isAllFiltersChecked, isAllFiltersDisabled - set state for toggle, default value true
 * showModal - set state for to show or hide modal, default state false
 *
 * Hooks Reducers:
 * serviceFocus - set state for Service Focus filters default value ''
 * corpsmemberFocus - set state for Corpsmembers Focus filters default value ''
 * ***************************************************************************
******************************************************************************/

import React, { useReducer, useEffect, useState } from 'react';

import Papa from 'papaparse';
import { Map } from '@esri/react-arcgis'; // 2D

import FeatureLayers from './FeatureLayers';
// import CensusFeatureLayer from './archive/CensusFeatureLayer';
import Sidebar from './Sidebar';
import { getFilters, replaceKeys } from "./data-helpers/csvDataHelper";
import { censusStateData } from './data-helpers/censusHelper';

import csvFile from '../data/CorpsNetworkMembersData.csv';

// Hook to load categories/filters
const reducer = (state, action) => {
    switch (action.active) {
        case 'OnSuccess':
            return (
                action.category
            );
        case 'OnFailure':
            return (
                action.error
            );
        case false:
            return state.map(focus => {
                if (focus.id === action.id) {
                    return { ...focus, active: true }
                } else {
                    return focus
                }
            });
        case true:
            return state.map(focus => {
                if (focus.id === action.id) {
                    return { ...focus, active: false }
                } else {
                    return focus
                }
            });
        default:
            return state
    }
};

/* Census data information. */
const censusState = censusStateData;

/* Initial map state to be modified when Census layers selected */
const initMapState = {
    url: "",
    variable: "",
    title: "",
    description: "",
    stops: [],
    normalization: null,
    totalPopulation: "",
    active: false,
    activeIndex: [-1, -1]
};

const Dashboard = (props) => {
    /* State variables to initialize state for service and corpsmember focus data. */
    const sfInit = [], cmfInit = [], errorInit = [];

    /* Set reducer for service and corpsmember focus data */
    const [error, dispatchError] = useReducer(reducer, errorInit),
        [serviceFocus, dispatchSF] = useReducer(reducer, sfInit),
        [corpsmemberFocus, dispatchCMF] = useReducer(reducer, cmfInit);

    /* State variable related to map functionality */
    const [isAllFiltersChecked, setIsAllFiltersChecked] = useState(true),
        [isAllFiltersDisabled, setIsAllFiltersDisabled] = useState(true);

    /* State for side panel events */
    const [isModalOpen, setModal] = useState(false); //state to keep track if modal is open

    /* States for Census layer */
    const [censusData, setCensusData] = useState(censusState);
    const [mapState, setMapState] = useState(initMapState);
    const [isCensusChecked, setCensusChecked] = useState(false),
        [isCensusDisabled, setCensusDisabled] = useState(true);

    useEffect(() => {
        //fetch csv data
        const fetchData = async () => {
            csvFile ?
                Papa.parse(csvFile, {
                    header: true,
                    download: true,
                    skipEmptyLines: true,
                    complete: getCsvData
                })
                : dispatchError({ active: 'OnFailure', error: [{ value: 'No Data Available' }] });
        };

        //get csv data
        const getCsvData = (result) => {
            const dataVar = result.data;
            dataVar.map(x => replaceKeys(x));

            const corpsMemberInit = getFilters(dataVar.map((ele) => ele.CorpsmemberFocus));
            const serviceFocusInit = getFilters(dataVar.map((ele) => ele.ServiceFocus));

            dispatchSF({
                active: 'OnSuccess',
                category: serviceFocusInit
            });

            dispatchCMF({
                active: 'OnSuccess',
                category: corpsMemberInit
            });
        };

        fetchData();
    }, []);

    // Reset all filters to default state
    const resetAllFilters = () => {
        setIsAllFiltersChecked(true);
        setIsAllFiltersDisabled(true);
        resetFiltersCMF();
        resetFiltersSF();
    };

    // Reset corpsmember focus to default state
    const resetFiltersCMF = () => {
        setIsAllFiltersChecked(true);
        setIsAllFiltersDisabled(true);
        corpsmemberFocus.map((ele) =>
            ele.active ? dispatchCMF({ active: ele.active, id: ele.id }) : null
        );
    };

    // Reset service focus to default state
    const resetFiltersSF = () => {
        setIsAllFiltersChecked(true);
        setIsAllFiltersDisabled(true);
        serviceFocus.map((ele) =>
            ele.active ? dispatchSF({ active: ele.active, id: ele.id }) : null
        );
    };

    // Turn on/off for selected filter in service focus
    const toggleSF = (focus) => {
        setIsAllFiltersChecked(false);
        setIsAllFiltersDisabled(false);
        dispatchSF({ active: focus.active, id: focus.id })
    };

    // Turn on/off for selected filter in corpsmember focus
    const toggleCMF = (focus) => {
        setIsAllFiltersChecked(false);
        setIsAllFiltersDisabled(false);
        dispatchCMF({ active: focus.active, id: focus.id })
    };

    //Turn off the census layer 
    const resetCensus = () => {
        setCensusChecked(false);
        setCensusDisabled(true);
        const newCensus = { ...censusData };
        // const newCensus = censusData.map((x) => x);
        const newMapState = { ...mapState };
        
        if (newMapState.activeIndex[0] >= 0) {
            newCensus[newMapState.activeIndex[0]].secondaryFields[newMapState.activeIndex[1]].active = false;
        }
        
        newMapState.url = "";
        newMapState.variable = "";
        newMapState.title = "";
        newMapState.description = "";
        newMapState.totalPopulation = "";
        newMapState.state = "";
        newMapState.county = "";
        newMapState.stops = [];
        newMapState.normalization = null;
        newMapState.active = false;
        
        for (const property in newCensus) {
          newCensus[property].active = false;
        }
        
        setMapState(newMapState);
        setCensusData(newCensus);
    };

    // Turn on/off Census fields
    const toggleCensus = (censusItem, i) => {
        const newCensus = { ...censusData };
        newCensus[i].active = !newCensus[i].active;
        setCensusData(newCensus);
    };

    // Turn on/off specific layers under each Census field
    const toggleCensusNestedState = (i, j, option2) => {
        setCensusChecked(true);
        setCensusDisabled(false);
        
        const newCensus = { ...censusData };
        const newMapState = { ...mapState };

        //Disable the previously selected census layer
        const oldi = newMapState.activeIndex[0];
        const oldj = newMapState.activeIndex[1];

        if (!newCensus[i].secondaryFields[j].active && (oldi !== i || (oldi === i && oldj !== j))) {
            newMapState.activeIndex[0] = i;
            newMapState.activeIndex[1] = j;
        }
        else {
            newMapState.activeIndex[0] = -1;
            newMapState.activeIndex[1] = -1;
        }

        if (oldi >= 0 && oldj >= 0) {
            newCensus[oldi].secondaryFields[oldj].active = false;
        }

        if (oldi !== i || (oldi === i && oldj !== j)) {
            newCensus[i].secondaryFields[j].active = !newCensus[i].secondaryFields[j].active;
        }

        //Sets the map's details for the newly selected census layer
        if (newCensus[i].secondaryFields[j].active) {
            newMapState.url = newCensus[i].url;
            newMapState.variable = newCensus[i].secondaryFields[j].variable;
            newMapState.title = newCensus[i].secondaryFields[j].display;
            newMapState.description = newCensus[i].description;
            newMapState.totalPopulation = newCensus[i].totalPopulation;
            newMapState.stops = newCensus[i].secondaryFields[j].stops;
            newMapState.normalization = newCensus[i].normalization;
            newMapState.state = newCensus[i].state;
            newMapState.county = newCensus[i].county;
            newMapState.active = true;
        }
        else {
            newMapState.url = "";
            newMapState.variable = "";
            newMapState.title = "";
            newMapState.description = "";
            newMapState.totalPopulation = "";
            newMapState.state = "";
            newMapState.county = "";
            newMapState.stops = [];
            newMapState.normalization = null;
            newMapState.active = false;
        }

        setCensusData(newCensus);
        setMapState(newMapState);
    };

    // Handle change for all filters checkbox
    const handleInputChange = (event) => {
        if (event.target.name === 'isAllFiltersChecked') {
            setIsAllFiltersChecked(!isAllFiltersChecked);

            //if checkbox is selected reset filters to default state so all markers show in map.
            if (!isAllFiltersChecked) {
                resetAllFilters();
            } else {
                return null;
            }
        }
        else {
            setCensusChecked(!isCensusChecked);

            if(isCensusChecked){
                resetCensus();
            } else {
                return null;
            }
        }
    };

    // Toggle: Modal
    const toggleModal = () => setModal(!isModalOpen);

    const handleMapLoad = (map, view) => {

    };

    const handleFail = (e) => {
        console.error(e);
    };

    /*Render Map*/
    return (
        <div style={{ width: '100vw', height: '100vh' }}>
            <Map
                id="6627e1dd5f594160ac60f9dfc411673f"
                class="corps-network-map"
                onLoad={handleMapLoad}
                onFail={handleFail}
                loaderOptions={{}}

                mapProperties={{
                    basemap: 'gray-vector',
                }}

                viewProperties={{
                    center: [-102.5795, 39.8283],
                    zoom: 3,
                    ui: {
                        // components: ["zoom"] // empty the UI, except for zoom
                    },
                    constraints: {
                        maxScale: 800000
                    },
                    highlightOptions: {
                        fillOpacity: 0.8,
                        color: [0, 58, 102],
                        haloOpacity: 0.7,
                    },
                }}
            >

                {/* If there is a url in mapState, show Census Feature Layer, otherwise show filter layer */}
                <FeatureLayers
                    sfFilters={serviceFocus.filter(focus => focus.active)}
                    cmfFilters={corpsmemberFocus.filter(focus => focus.active)}
                    isCensusChecked={isCensusChecked}
                    mapState={mapState}
                />
            </Map>

            <Sidebar
                isModalOpen={isModalOpen}
                toggleModal={toggleModal}
                errorMsg={error}
                sfFilterOptions={serviceFocus}
                cmfFilterOptions={corpsmemberFocus}
                toggleCMF={toggleCMF}
                toggleSF={toggleSF}
                handleInputChange={handleInputChange}
                isAllFiltersChecked={isAllFiltersChecked}
                isAllFiltersDisabled={isAllFiltersDisabled}
                resetAllFilters={resetAllFilters}
                resetFiltersSF={resetFiltersSF}
                resetFiltersCMF={resetFiltersCMF}
                isCensusDisabled={isCensusDisabled}
                isCensusChecked={isCensusChecked}
                resetCensus={resetCensus}
                censusOptions={censusState}
                toggleCensus={toggleCensus}
                toggleMap={toggleCensusNestedState}
            />
        </div>
    );
};

export default Dashboard;
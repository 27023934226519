/**
 * Data for Dashboard's Census State, contains the API URLs, variables, and data for visualizing the census layer.
 */
const lightColor = [192, 201, 206, 0.4];
const darkColor = [65, 92, 102, 0.8];

export const censusStateData =
    [{
        primaryFieldDisplay: "Age/Adult Population",
        primaryField: "",
        id: "census0",
        normalization: "TOTPOP_CY",
        totalPopulation: "TOTPOP_CY",
        state: "ST_ABBREV",
        county: "NAME",
        url: "https://services.arcgis.com/P3ePLMYs2RVChkJx/ArcGIS/rest/services/AGOL_Base_2018_Final/FeatureServer/2",
        secondaryFields: [{
            display: 'Total Population Age 15-19',
            id: 'census03',
            variable: "POP15_CY",
            active: false,
            stops: [{
                value: .05,
                color: lightColor,
                label: "<5%"
            },{
                value: .08,
                color: darkColor,
                label: ">8%"
            }]
        },{
            display: 'Total Population Age 20-24',
            id: 'census04',
            variable: "POP20_CY",
            active: false,
            stops: [{
                value: .05,
                color: lightColor,
                label: "<5%"
            },{
                value: .08,
                color: darkColor,
                label: ">8%"
            }]
        }, {
            display: 'Total Population Age 25-29',
            id: 'census05',
            variable: "POP25_CY",
            active: false,
            stops: [{
                value: .05,
                color: lightColor,
                label: "<5%"
            }, {
                value: .08,
                color: darkColor,
                label: ">8%"
            }]
        }],
        
        description: "From Esri 2018 demographic estimates.",
        active: false
    },
    {
        primaryFieldDisplay: "Educational Attainment",
        primaryField: "",
        id: "census1",
        normalization: null,
        totalPopulation: "B15002_001E",
        state: "State",
        county: "NAME",
        url: "https://services.arcgis.com/P3ePLMYs2RVChkJx/ArcGIS/rest/services/ACS_Educational_Attainment_Boundaries/FeatureServer/1",
        secondaryFields: [{
            display: 'Less than High School',
            id: 'census10',
            variable: "B15002_calc_pctLTHSE",
            active: false,
            stops: [{
                value: 10,
                color: lightColor,
                label: "<10%"
            }, {
                value: 20,
                color: darkColor,
                label: ">20%" }]
        }, {
            display: 'High School (and equivalent)',
            id: 'census11',
            variable: "B15002_calc_pctHSE",
            active: false,
            stops: [{
                value: 20,
                color: lightColor,
                label: "<20%"
            }, {
                value: 40,
                color: darkColor,
                label: ">40%"
            }]
        }, {
            display: 'Some College',
            id: 'census12',
            variable: "B15002_calc_pctSomeCollE",
            active: false,
            stops: [{
                value: 15,
                color: lightColor,
                label: "<15%"
            }, {
                value: 30,
                color: darkColor,
                label: ">30%"
            }]
        },  {
            display: 'Associates Degree',
            id: 'census13',
            variable: "B15002_calc_pctAAE",
            active: false,
            stops: [{
                value: 5,
                color: lightColor,
                label: "<10%"
            }, {
                value: 10,
                color: darkColor,
                label: ">20%"
            }]
        }, {
            display: 'Bachelor Degree',
            id: 'census14',
            variable: "B15002_calc_pctGEBAE",
            active: false,
            stops: [{
                value: 10,
                color: lightColor,
                label: "<10%"
            }, {
                value: 25,
                color: darkColor,
                label: ">25%"
            }]
        }],
        description: "From 2018 five year estimate from ACS for adults 25+.",
        active: false
    },
    {
        primaryFieldDisplay: "Race",
        primaryField: "",
        id: "census2",
        normalization: "TOTPOP_CY",
        totalPopulation: "TOTPOP_CY",
        state: "ST_ABBREV",
        county: "NAME",
        url: "https://services.arcgis.com/P3ePLMYs2RVChkJx/ArcGIS/rest/services/AGOL_Base_2018_Final/FeatureServer/2",
        secondaryFields: [{
            display: 'Hispanic Population',
            id: 'census20',
            variable: "HISPPOP_CY",
            active: false,
            stops: [{
                value: .05,
                color: lightColor,
                label: "<5%"
            }, {
                value: .3,
                color: darkColor,
                label: ">30%" }]
        }, {
            display: 'White Non-Hispanic Population',
            id: 'census21', variable: "NHSPWHT_CY",
            active: false,
            stops: [{
                value: .10,
                color: lightColor,
                label: "<10%"
            }, {
                value: .20,
                color: darkColor,
                label: ">20%"
            }]
        }, {
            display: 'Black/African American Non-Hispanic',
            id: 'census22',
            variable: "NHSPBLK_CY",
            active: false,
            stops: [{
                value: .10,
                color: lightColor,
                label: "<10%"
            }, {
                value: .20,
                color: darkColor,
                label: ">20%"
            }]
        }, {
            display: 'American Indian/Alaska Native Non-Hispanic Population',
            id: 'census23',
            variable: "NHSPAI_CY",
            active: false,
            stops: [{
                value: .10,
                color: lightColor,
                label: "<10%"
            }, {
                value: .20,
                color: darkColor,
                label: ">20%"
            }]
        }, {
            display: 'Asian Non-Hispanic Population',
            id: 'census24', variable: "NHSPASN_CY",
            active: false,
            stops: [{
                value: .02,
                color: lightColor,
                label: "<2%"
            }, {
                value: .10,
                color: darkColor,
                label: ">10%"
            }]
        }, {
            display: 'Pacific Islander Non-Hispanic Population',
            id: 'census25',
            variable: "NHSPPI_CY",
            active: false,
            stops: [{
                value: .0005,
                color: lightColor,
                label: "<0.05%"
            }, {
                value: .005,
                color: darkColor,
                label: ">0.5%"
            }]
        }, {
            display: 'Other Race Non-Hispanic Population',
            id: 'census26',
            variable: "NHSPOTH_CY",
            active: false,
            stops: [{
                value: .001,
                color: lightColor,
                label: "<0.1%"
            }, {
                value: .005,
                color: darkColor,
                label: ">0.5%"
            }]
        }, {
            display: 'Multiple Races Non-Hispanic Population',
            id: 'census27', variable: "NHSPMLT_CY",
            active: false,
            stops: [{
                value: .01,
                color: lightColor,
                label: "<1%"
            }, {
                value: .02,
                color: darkColor,
                label: ">2%"
            }]
        }],
        description: "From Esri 2018 demographic estimates.",
        active: false
    },
    {
        primaryFieldDisplay: "Ethnicity",
        primaryField: "",
        id: "census3",
        normalization: null,
        totalPopulation: "B03002_001E",
        state: "State",
        county: "NAME",
        url: "https://services.arcgis.com/P3ePLMYs2RVChkJx/ArcGIS/rest/services/ACS_Population_by_Race_and_Hispanic_Origin_Boundaries/FeatureServer/1",
        secondaryFields: [{
            display: 'Percent of Population that is Hispanic or Latino',
            id: 'census30',
            variable: "B03002_calc_pctHispLatE",
            active: false,
            stops: [{
                value: 3,
                color: lightColor,
                label: "<3%"
            }, {
                value: 30,
                color: darkColor,
                label: ">30%"
            }]
        }],
        description: "From 2018 five year estimate from ACS, broken by race and Hispanic origin.",
        active: false
    },
    {
        primaryFieldDisplay: "Unemployment",
        primaryField: "",
        id: "census4",
        normalization: null,
        totalPopulation: "TOTPOP_CY",
        state: "State",
        county: "NAME",
        url: "https://services.arcgis.com/P3ePLMYs2RVChkJx/ArcGIS/rest/services/BLS_Monthly_Unemployment_Current_14_Months/FeatureServer/2",
        secondaryFields: [{
            display: 'Unemployment Rate',
            id: 'census40',
            variable: "PctUnemployed_CurrentMonth",
            active: false,
            stops: [{
                value: 3,
                color: lightColor,
                label: "<3%"
            }, {
                value: 20,
                color: darkColor,
                label: ">20%"
            }]
        }],
        description: "Monthly Bureau of Labor Statistics data, currently May 2020.",
        active: false
    },
    {
        primaryFieldDisplay: "Employment Status",
        primaryField: "",
        id: "census5",
        normalization: "B23025_001E",
        totalPopulation: "LaborForce_CurrentMonth",
        state: "State",
        county: "NAME",
        url: "https://services.arcgis.com/P3ePLMYs2RVChkJx/ArcGIS/rest/services/ACS_Employment_Status_Boundaries/FeatureServer/1",
        secondaryFields: [{
            display: 'Employed Population in Civilian Labor Force',
            id: 'census50',
            variable: "B23025_004E",
            active: false,
            stops: [{
                value: .4,
                color: lightColor,
                label: "<40%"
            }, {
                value: .70,
                color: darkColor,
                label: ">70%"
            }]
        }, {
            display: 'Unemployed Population in Civilian Labor Force',
            id: 'census51',
            variable: "B23025_005E",
            active: false,
            stops: [{
                value: .01,
                color: lightColor,
                label: "<1%"
            }, {
                value: .05,
                color: darkColor,
                label: ">5%"
            }]
        }, {
            display: 'Population in Armed Forces',
            id: 'census52',
            variable: "B23025_006E",
            active: false,
            stops: [{
                value: .001,
                color: lightColor,
                label: "<0.1%"
            }, {
                value: .01,
                color: darkColor,
                label: ">1%"
            }]
        }, {
            display: 'Population 16 Years and Over Not in Labor Force',
            id: 'census53',
            variable: "B23025_007E",
            active: false,
            stops: [{
                value: .3,
                color: lightColor,
                label: "<30%"
            }, {
                value: .5,
                color: darkColor,
                label: ">50%"
            }]
        }],
        
        description: "From 2018 five year estimate from ACS.",
        active: false
    },
    // {
    //     primaryFieldDisplay: "Employer Industry",
    //     primaryField: "",
    //     id: "census6",
    //     normalization: null,
    //     url: "https://services.arcgis.com/iTQUx5ZpNUh47Geb/ArcGIS/rest/services/ACS_16_5YR_DP03/FeatureServer/3",
    //     secondaryFields: [
    //         { display: 'Agriculture, forestry, fishing and hunting, and mining', id: 'census60', variable: "HC03_VC50", active: false, stops: [{ value: 5, color: lightColor, label: "<5%" }, { value: 10, color: darkColor, label: ">10%" }] },
    //         { display: 'Construction', id: 'census61', variable: "HC03_VC51", active: false, stops: [{ value: 5, color: lightColor, label: "<5%" }, { value: 10, color: darkColor, label: ">10%" }] },
    //         { display: 'Manufacturing', id: 'census62', variable: "HC03_VC52", active: false, stops: [{ value: 5, color: lightColor, label: "<5%" }, { value: 10, color: darkColor, label: ">10%" }] },
    //         { display: 'Wholesale trade', id: 'census63', variable: "HC03_VC53", active: false, stops: [{ value: 5, color: lightColor, label: "<5%" }, { value: 10, color: darkColor, label: ">10%" }] },
    //         { display: 'Retail trade', id: 'census64', variable: "HC03_VC54", active: false, stops: [{ value: 5, color: lightColor, label: "<5%" }, { value: 10, color: darkColor, label: ">10%" }] },
    //         { display: 'Transportation and warehousing, and utilities', id: 'census65', variable: "HC03_VC55", active: false, stops: [{ value: 5, color: lightColor, label: "<5%" }, { value: 10, color: darkColor, label: ">10%" }] },
    //         { display: 'Information', id: 'census66', variable: "HC03_VC56", active: false, stops: [{ value: 5, color: lightColor, label: "<5%" }, { value: 10, color: darkColor, label: ">10%" }] },
    //         { display: 'Finance and insurance', id: 'census67', variable: "HC03_VC57", active: false, stops: [{ value: 5, color: lightColor, label: "<5%" }, { value: 10, color: darkColor, label: ">10%" }] },
    //         { display: 'Professional, scientific, and management, and administrative and waste management', id: 'census68', variable: "HC03_VC58", active: false, stops: [{ value: 5, color: lightColor, label: "<5%" }, { value: 10, color: darkColor, label: ">10%" }] },
    //         { display: 'Educational services, and health care and social assistance', id: 'census69', variable: "HC03_VC59", active: false, stops: [{ value: 5, color: lightColor, label: "<5%" }, { value: 10, color: darkColor, label: ">10%" }] },
    //         { display: 'Arts, entertainment, and recreation, and accommodation and food services', id: 'census610', variable: "HC03_VC60", active: false, stops: [{ value: 5, color: lightColor, label: "<5%" }, { value: 10, color: darkColor, label: ">10%" }] },
    //         { display: 'Other services, except public administration', id: 'census611', variable: "HC03_VC61", active: false, stops: [{ value: 5, color: lightColor, label: "<5%" }, { value: 10, color: darkColor, label: ">10%" }] },
    //         { display: 'Public administration', id: 'census612', variable: "HC03_VC62", active: false, stops: [{ value: 10, color: lightColor, label: "" }, { value: 20, color: darkColor, label: "" }] }],
    //     active: false
    // },
    {
        primaryFieldDisplay: "Poverty",
        primaryField: "",
        id: "census7",
        normalization: null,
        totalPopulation: "E_TOTPOP",
        state: "ST_ABBR",
        county: "COUNTY",
        url: "https://services3.arcgis.com/ZvidGQkLaDJxRSJ2/ArcGIS/rest/services/CDC_Social_Vulnerability_Index_2018/FeatureServer/1",
        secondaryFields: [{
            display: 'Percentage of persons below poverty',
            id: 'census70',
            variable: "EP_POV",
            active: false,
            stops: [{
                value: 10,
                color: lightColor,
                label: "<10%"
            }, {
                value: 20,
                color: darkColor,
                label: ">20%"
            }]
        }],
        description: "From 2018 Social Vulnerability Index from CDC.",
        active: false
    },
    {
        primaryFieldDisplay: "Median Income",
        primaryField: "",
        id: "census8",
        normalization: null,
        totalPopulation: "TOTPOP_CY",
        state: "ST_ABBREV",
        county: "NAME",
        url: "https://services.arcgis.com/P3ePLMYs2RVChkJx/ArcGIS/rest/services/AGOL_Base_2018_Final/FeatureServer/2",
        secondaryFields: [{
            display: 'Median Household Income',
            id: 'census80', variable: "MEDHINC_CY",
            active: false,
            stops: [{
                value: 30000,
                color: lightColor,
                label: "<$30,000"
            }, {
                value: 90000,
                color: darkColor,
                label: ">$90,000"
            }]
        }],
        description: "From Esri 2018 demographic estimates.",
        active: false
    },
    ];
